import { inject, Injectable, Signal } from '@angular/core'
import { Router } from '@angular/router'
import { AppStore } from '@lib/store/AppStore'
import { OAuthService } from '../auth/oauth.service'
import { ExtendSessionModalService } from '../modals/extend-session-modal.service'
import { NotificationsService } from '../notifications.service'

/**
 * Servicio que contiene la lógica del manejo de tokens de autenticación
 */
@Injectable({
	providedIn: 'root',
})
export class TokenService {
	timeout!: any
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	appStore = inject(AppStore)

	constructor(
		private readonly oauthService: OAuthService,
		private readonly modalService: ExtendSessionModalService,
		private readonly router: Router,
		private readonly notificationsService: NotificationsService
	) {}

	// Limpiamos el temporizador, cuando el usuario cierre sesión de forma manual
	stopAutomaticTokenRefresh(): void {
		clearTimeout(this.timeout)
	}

	// Espera a que el token esté disponible
	waitForToken(): Promise<void> {
		return new Promise((resolve) => {
			// Verificar si el PKCE verifier y el access token ya están disponibles en el localStorage
			if (this.appStore.userInfo()?.idToken) {
				resolve()
				return // No es necesario seguir con la ejecución del código
			}

			// Si no hay PKCE verifier, resolver la promesa directamente ya que el usuario no inició sesión con OAuth OIDC y no es necesario esperar el token
			resolve()
		})
	}

	// Verifica si el token está a punto de expirar
	async initAutomaticTokenRefresh(): Promise<void> {
		const sessionExpiration: number = this.appStore.userInfo()?.exp || 0
		const ONE_MINUTE = 60_000
		const nowUnix: number = new Date().getTime() // Tiempo actual en segundos

		console.log('EXPIRACION TOKEN', sessionExpiration)
		console.log('TIEMPO ACTUAL', nowUnix)
		// Validamos si la sesión ya expiró
		if (nowUnix > sessionExpiration) {
			this.signOutAndGoToLogin()
			this.notificationsService.error('Su sesión ha expirado')
			return
		}

		const diff: number = sessionExpiration - nowUnix // Segundos restantes para la expiración
		console.log(diff)

		// Validamos si la sesión expira en menos de 60 segundos
		if (diff < 60) {
			this.signOutAndGoToLogin()
			this.notificationsService.error('Su sesión ha sido finalizada.')
			return
		}

		// diff = diff - ONE_MINUTE // Restamos 60 segundos para mostrar el modal antes de la expiración

		const timeRemaining: number = diff - ONE_MINUTE // Convertimos los segundos restantes a milisegundos
		console.log('DIFF', diff, 'TIME REMAINING', timeRemaining)

		// Configura el temporizador
		this.timeout = setTimeout(() => {
			clearTimeout(this.timeout) // Limpiamos el temporizador
			this.showModal() // Mostramos el modal de extensión de sesión
		}, timeRemaining)
	}

	// Cierra la sesión del usuario
	private async signOutAndGoToLogin(): Promise<void> {
		// Validamos si el usuario inició sesión con OAuth
		this.oauthService.logout()

		this.router.navigate(['/auth/login'])
	}

	// Muestra el modal de extensión de sesión
	private showModal(): void {
		this.modalService.openModal().subscribe(async (result): Promise<void> => {
			// Validamos si el usuario aceptó extender la sesión
			if (result) {
				await this.oauthService.refreshToken()
				this.initAutomaticTokenRefresh()
			}
		})
	}
}
