import { Injectable } from '@angular/core'
import { HotToastService, ToastPosition } from '@ngneat/hot-toast'

interface IToastOptions {
	style: {
		border: string
		padding: string
		color: string
		backgroundColor: string
	}
	iconTheme: {
		primary: string
		secondary: string
	}
	position: ToastPosition
	dismissible: boolean
}

/**
 * Servicio que contiene la lógica para mostrar notificaciones
 */
@Injectable({
	providedIn: 'root',
})
export class NotificationsService {
	constructor(private readonly toast: HotToastService) {}

	success(message: string): void {
		this.toast.success(message, this.getToastOptions('#4ade80', '#166534'))
	}

	info(message: string): void {
		this.toast.info(message, this.getToastOptions('#60a5fa', '#2769de'))
	}

	error(message: string): void {
		this.toast.error(message, this.getToastOptions('#f87171', '#ef4444'))
	}

	private getToastOptions(primaryColor: string, color: string): IToastOptions {
		return {
			style: {
				border: `1px solid ${color}`,
				padding: '16px',
				color: color,
				backgroundColor: '#fff',
			},
			iconTheme: {
				primary: color,
				secondary: '#fff',
			},
			position: 'top-right',
			dismissible: true,
		}
	}
}
