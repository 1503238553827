import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
	providedIn: 'root',
})
export class ExtendSessionModalService {
	isModalOpen: boolean = false

	private result!: Subject<boolean>

	constructor() {}

	openModal(): Subject<boolean> {
		this.isModalOpen = true // Abrimos el modal

		this.result = new Subject<boolean>() // Creamos un nuevo subject para emitir el resultado del modal

		// Devolvemos el subject para que el componente que abrió el modal pueda suscribirse a él
		return this.result
	}

	closeModal(result: boolean): void {
		this.result.next(result) // Emitimos el resultado del modal
		this.result.complete() // Completamos el subject para que no se pueda seguir emitiendo valores

		if (result) {
			this.isModalOpen = false
			return
		}

		// Esperamos unos segundos antes de cerrar el modal
		setTimeout((): void => {
			this.isModalOpen = false
		}, 5000)
	}
}
